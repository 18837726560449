import { whiteListAddress, WhitelistMintABI } from "../../config/abis";
import { isValidSkinColor, saveToExportQueue } from "../../config/requests";
import { BLOCKCHAIN_QUERY_PARAM } from "../../config/variables";
import { NotValidError } from "../../errors/error";
import { ExportQueue, Footballer } from "../../models/model";
import { ButtonMint } from "../TxForm/ButtonMint";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { formatEther } from "viem";
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

interface IEthMintButton {
  color: string;
}

export default function EthMintButton({ color }: IEthMintButton) {
  const [price, setPrice] = useState<bigint>(BigInt(0));
  const { address } = useAccount();
  const priceFor = useReadContract({
    abi: WhitelistMintABI,
    address: whiteListAddress,
    functionName: "priceFor",
    args: [address ?? "0x", BigInt(1)],
  });
  const { writeContractAsync, data: hash } = useWriteContract();
  const { data, error } = useWaitForTransactionReceipt({
    hash: hash,
    confirmations: 0,
  });

  useEffect(() => {
    const response = priceFor.data;
    if (response) {
      setPrice(response);
    }
  }, [address, priceFor]);

  const saveToQueue = async () => {
    try {
      const queue = getExportQueue(address);
      const params = new URLSearchParams();
      params.append(BLOCKCHAIN_QUERY_PARAM, "ETH");
      const isValid = await isValidSkinColor(params, color);

      if (!isValid.ok) {
        throw new NotValidError("Could not mint this color");
      }

      const response = await saveToExportQueue(queue);
      console.log(response);
    } catch (e: any) {
      let message = "Send transaction error";
      let description = e.message ?? "";

      if (typeof e === "object" && e instanceof NotValidError) {
        message = "Color is not available";
        description = "Please try again and choose another color.";
      }
      notification.error({
        message,
        description,
      });
      console.log(e);
    }
  };

  const handleMint = async () => {
    try {
      const params = new URLSearchParams();
      params.append(BLOCKCHAIN_QUERY_PARAM, "ETH");
      const isValid = await isValidSkinColor(params, color);

      if (!isValid.ok) {
        throw new NotValidError("Could not mint this color");
      }

      if (!address) return;

      const responseEth = await writeContractAsync({
        abi: WhitelistMintABI,
        address: whiteListAddress,
        functionName: "mint",
        args: [BigInt(1)],
        value: price,
      });

      console.log(responseEth);
      notification.info({
        message: "Waiting for confirm your operation...",
        duration: 10,
      });

      console.log("Transaction successful:", data);
      await saveToQueue();

      notification.success({
        message: "Successful transaction",
        description:
          "You transaction was successfully sent. Please wait until the transaction is included to the ETH blockchain. Player NFT minted to your wallet.",
        duration: 10,
      });
    } catch (e: any) {
      let message = "Send transaction error";
      let description = e.message ?? "";

      if (typeof e === "object" && e instanceof NotValidError) {
        message = "Color is not available";
        description = "Please try again and choose another color.";
      }
      notification.error({
        message,
        description,
      });
      console.log(e, error);
    }
  };

  const getExportQueue = (address: `0x${string}` | undefined) => {
    const footballer = new Footballer();
    footballer.skinColor = color;
    const queue = new ExportQueue();
    queue.skinColor = footballer.skinColor;
    queue.addressId = address;
    queue.operation = "mint";
    queue.blockchain = "ETH";
    return queue;
  };

  return (
    <ButtonMint className="mint__button" onClick={handleMint}>
      Buy {formatEther(price)}ETH
    </ButtonMint>
  );
}
