import { ScContacts } from "src/components/contacts/styled";
import { ScSocial } from "src/components/ui/Social/styled";

const labelMap = {
  project: "About the project",
  twitter: "Twitter",
  discord: "Discord",
  invest: "I want to invest",
  telegramEn: "Telegram EN",
  telegramCis: "Telegram CIS",
  weibo: "Weibo",
};

const linkMap = {
  project: "https://shaolin.soccer/",
  twitter: "https://twitter.com/shaolingame",
  discord: "https://discord.gg/Pg4pVsxHQX",
  invest: "https://t.me/kickoffninja",
  telegramEn: "https://t.me/shaolintoken",
  telegramCis: "https://t.me/shaolintoken_ru",
  weibo: "https://weibo.com/u/7950906929",
};

export const Contacts = () => (
  <ScContacts>
    <ScSocial>
      <a target="_blank" href={linkMap.project}>
        {labelMap.project}
      </a>
    </ScSocial>
    <p>Join the community</p>
    <div className="socials">
      <ScSocial>
        <a target="_blank" href={linkMap.twitter}>
          {labelMap.twitter}
        </a>
      </ScSocial>
      <ScSocial>
        <a target="_blank" href={linkMap.discord}>
          {labelMap.discord}
        </a>
      </ScSocial>
      <ScSocial>
        <a target="_blank" href={linkMap.telegramEn}>
          {labelMap.telegramEn}
        </a>
      </ScSocial>
      <ScSocial>
        <a target="_blank" href={linkMap.telegramCis}>
          {labelMap.telegramCis}
        </a>
      </ScSocial>
      <ScSocial>
        <a target="_blank" href={linkMap.weibo}>
          {labelMap.weibo}
        </a>
      </ScSocial>
    </div>
    <ScSocial>
      <a target="_blank" href={linkMap.invest}>
        {labelMap.invest}
      </a>
    </ScSocial>
  </ScContacts>
);
